var exports = {};

function NoVideoInputDevicesError() {
  this.name = "NoVideoInputDevicesError";
  this.message = "No video input devices found";
}

NoVideoInputDevicesError.prototype = new Error();
exports = {
  NoVideoInputDevicesError: NoVideoInputDevicesError
};
export default exports;